import React from 'react'
import { graphql, Link } from 'gatsby'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import HeroImage from '../components/contentful/hero-image'
import { FaStore } from 'react-icons/fa'
import { buildBreadcrumbsItem } from '../helpers'
import styles from '../scss/page.scss'

class StoresPage extends React.Component {  
  constructor(props) {
    super(props);
    this.initStoresWidget = this.initStoresWidget.bind(this)
    this.widgetId = 'store-locator-widget'
    this.widgetConfig = {
      selector: `#${this.widgetId}`,
      account: this.props.data.site.siteMetadata.storeRocketId
    }
    const title = 'Locate a Store near you | Honest Paws®'
    this.pageUrl = this.props.location.href
    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'Check and see what retailers are selling Honest Paws near you!'
      },
      children: [
        <link rel="canonical" href={ this.pageUrl } key='canonical-url' />
      ]
    }
    
    this.breadcrumbs = [
      buildBreadcrumbsItem('Locate a Store', this.props.location.href)
    ]
  }
  componentDidMount() {
    if ('StoreRocket' in window === false) {
      // Means it wasn't added
      const script = document.createElement('script')
      script.src = `//cdn.storerocket.io/widget.js`
      script.setAttribute('id', this.scriptId)
      script.setAttribute('defer', true)
      document.head.append(script)
      // We want to make sure the widget initializes right after the script is loaded
      if (script.readyState) {  // Required for IE <9
        script.onreadystatechange = function() {
          if ( script.readyState === 'loaded' || script.readyState === 'complete' ) {
            script.onreadystatechange = null;
            this.initStoresWidget()
          }
        };
      } else {  //Others
        script.onload = this.initStoresWidget
      }
    } else {
      this.initStoresWidget()
    }
  }
  
  /**
   * Initializes widget (https://storerocket.io/)
   * @return {void}
   */
  initStoresWidget() {
    if ('StoreRocket' in window) {
      StoreRocket.init(this.widgetConfig);
    }
  }
  render() {
    return (
      <Layout containerClassName={`page page-stores`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={this.props.location} >
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary page-header text-center px-4">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">Locate a Store</h1>       
            </div>
          </div>
        </section>
        <section className="bg-light pt-0">
          <div id={this.widgetId}>
            <p className="text-center p-6 pb-0">
              Stores are loading...
            </p>
          </div>
        </section>
      </Layout>
    )
  }
}

export default StoresPage

export const StoresQuery = graphql`
  query StoresQuery {
    site {
      siteMetadata {
        title
        siteUrl
        storeRocketId
      }
    }
  }
`